#autochartist {
  .main {
    display: grid;
    grid-template-columns: calc(50% - 47.5px) calc(50% - 47.5px);
    max-width: 1215px;
    margin: 0 auto;
    column-gap: 95px;
    row-gap: 20px;

    .box {
      min-height: 280px;
      border-radius: 6px;
      background-color: $white;
      position: relative;

      .top {
        .shadow {
          position: absolute;
          top: 23px;
          @include rtl-sass-prop(left, right, -8px);
          @include rtl-sass-prop(right, left, unset);
          content: url(~@/assets/images/autochartist/shadow.png);
        }

        .title {
          position: absolute;
          top: 23px;
          @include rtl-sass-prop(left, right, -8px);
          @include rtl-sass-prop(right, left, unset);
          @include rtl-sass-value(padding, 0 50px 0 30px, 0 30px 0 50px);
          display: inline-block;
          background: rgb(160, 160, 160);
          height: 40px;
          line-height: 40px;
          @include rtl-sass-value(
            clip-path,
            polygon(0 0, 100% 0, 85% 100%, 0% 100%),
            polygon(0 0, 100% 0, 100% 100%, 15% 100%)
          );
          font-size: 17px;
          color: $light-gray;
        }
      }

      .bottom {
        overflow: hidden;
        position: relative;
        height: 100%;
        width: 100%;
        padding: 20px 40px;
        justify-content: space-between;
        flex-direction: column;
        display: flex;

        .content {
          margin: 60px 0 0;
          font-size: 15px;
          color: rgb(160, 160, 160);
          line-height: 1.2;

          ul {
            font-size: 12px;
            li {
              list-style: decimal;
              @include rtl-sass-prop(margin-left, margin-right, 15px);

              &:last-child {
                margin-bottom: 40px;
              }
            }
          }
        }

        .left {
          position: absolute;
          @include rtl-sass-prop(left, right, -5px);
          @include rtl-sass-prop(right, left, unset);
          bottom: -10px;
        }

        .right {
          @include rtl-sass-value(text-align, right, left);

          .el-button {
            font-size: 12px;
            padding: 15px 20px;
            border-width: 2px;
            border-color: rgb(160, 160, 160);
            border-style: solid;
            border-radius: 4px;
            color: rgb(160, 160, 160);
            text-transform: none;

            &:hover {
              border-style: solid;
              border-width: $border-width;
              border-color: $border-color;
              background-color: $blue;
              color: $white;
            }
          }

          .el-button {
            @include rtl-sass-prop(margin-right, margin-left, 12px);
          }
        }
      }

      &.qrcode .bottom .left {
        @include rtl-sass-prop(left, right, 20px);
        @include rtl-sass-prop(right, left, unset);
        bottom: 20px;
        img {
          width: 71px;
        }
      }

      &:first-child .bottom .left {
        content: url(~@/assets/images/autochartist/icon01.png);
      }

      &:nth-child(2) .bottom .left {
        content: url(~@/assets/images/autochartist/icon02.png);
      }

      &:nth-child(3) .bottom .left {
        content: url(~@/assets/images/autochartist/icon03.png);
      }

      &:nth-child(4) .bottom .left {
        content: url(~@/assets/images/autochartist/icon04.png);
      }

      &:nth-child(6) .bottom .left {
        content: url(~@/assets/images/autochartist/icon06.png);
      }

      &:hover {
        border-style: 1px solid $border-color;
        box-shadow: 0.572px 2.945px 4px 0px rgba(19, 33, 116, 0.15);

        .top {
          .shadow {
            content: url(~@/assets/images/autochartist/shadow_active.png);
          }

          .title {
            background-color: $future-blue;
          }
        }

        &:first-child .bottom .left {
          content: url(~@/assets/images/autochartist/icon01_active.png);
        }

        &:nth-child(2) .bottom .left {
          content: url(~@/assets/images/autochartist/icon02_active.png);
        }

        &:nth-child(3) .bottom .left {
          content: url(~@/assets/images/autochartist/icon03_active.png);
        }

        &:nth-child(4) .bottom .left {
          content: url(~@/assets/images/autochartist/icon04_active.png);
        }

        &:nth-child(6) .bottom .left {
          content: url(~@/assets/images/autochartist/icon06_active.png);
        }
      }
    }
  }

  .more {
    margin: 50px auto 0;
    max-width: 1215px;
    position: relative;

    .el-button {
      position: absolute;
      top: 65%;
      @include rtl-sass-prop(right, left, calc(50% - 65px));
      @include rtl-sass-prop(left, right, unset);
      font-size: 12px;
      line-height: 1.2;
      border-radius: 4px;
      padding: 10px 20px;
      min-width: 130px;
    }
  }

  .footer {
    max-width: 1215px;
    margin: 65px auto 0;

    img {
      display: block;
      margin-bottom: 30px;
    }

    span {
      font-size: 15px;
      color: rgb(160, 160, 160);
      line-height: 1.2;
    }
  }
}

@media (max-width: 1400px) {
  #autochartist {
    .main {
      grid-template-columns: calc(50% - 10px) calc(50% - 10px);
      column-gap: 20px;
    }

    .more .el-button {
      top: 60%;
    }
  }
}

@media (max-width: 1200px) {
  #autochartist .main {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}

@media (max-width: 600px) {
  #autochartist .more .el-button {
    top: 20%;
  }
}

@media (max-width: 550px) {
  #autochartist .main {
    .box .bottom {
      padding: 20px;
      .content ul li:last-child {
        margin-bottom: 0;
      }
      .right {
        .el-button {
          display: block;
          @include rtl-sass-value(float, right, left);
          margin-top: 12px;
        }
      }
    }

    .box.qrcode .bottom .left {
      @include rtl-sass-prop(left, right, 5px);
      @include rtl-sass-prop(right, left, unset);
      bottom: 10px;
    }
  }
}
